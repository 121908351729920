import PropTypes from "prop-types"
import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroupMap from "../Button/ButtonGroupMap"
import CTA from "./CTA"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class GetStarted extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    // One or multiple buttons
    let buttonColumns = (
      <div className="column">
        <Button
          href={this.props.buttonUrl}
          buttonText={this.props.buttonText}
        />
      </div>
    )

    if (this.props.buttons && this.props.buttons.length > 1) {
      buttonColumns = (
        <div className="column">
          <ButtonGroupMap isCentered buttons={this.props.buttons} />
        </div>
      )
    }

    // CSS classes
    let headingClasses = classNames("column", {
      "has-text-centered": this.props.centerHeading
    })
    let textClasses = classNames("column", {
      "has-text-centered": this.props.centerText
    })

    return (
      <CTA
        className={this.props.className ? this.props.className : ""}
        sideColumnIs={this.props.sideColumnIs}
        headingSideColumnIs={this.props.headingSideColumnIs}
        headingColumns={
          <div className={headingClasses}>
            {this.props.subheading && <h4>{this.props.subheading}</h4>}
            <h3>{this.props.heading}</h3>
          </div>
        }
        textColumns={<div className={textClasses}>{this.props.paragraph}</div>}
        buttonColumns={buttonColumns}
      />
    )
  }
}

GetStarted.propTypes = {
  className: PropTypes.string,
  centerHeading: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  centerText: PropTypes.bool,
  paragraph: PropTypes.element,
  buttons: PropTypes.array,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.string,
  sideColumnIs: PropTypes.number,
  headingSideColumnIs: PropTypes.number
}

export default GetStarted
